<template>
  <div class="downloadxx">
    <div class="head">
      <el-image :src="url"
                style="background-size: cover; height: 27.0833vw; width: 100%">
      </el-image>
    </div>
    <div class="new-h1"
         style="height:50px"></div>
  <el-tabs tab-position="left" style="">
    <el-tab-pane label="全部版本">
      <div class="new-h1">
      <div style="display: flex;
    flex-wrap: wrap;
    justify-content: space-around;">
        <el-card shadow="hover">
          <img src="@/assets/ZX G3.png"
               style="width:250px;height:220px"
               class="image" />
          <div style="padding: 20px">
            <span>ZXG3 4G版</span>
          </div>
        </el-card>
        <div style="
    background: rgba(0, 0, 0, 0);flex:1">
          <div style="padding-bottom: 20px;padding-left:20px">
            <span>巡检平台手机APP
              支持三大常用移劢平台，安卓、Hamony OS、IOS，APP端包括不WEB端除电子地图以外的全部功能。用户完合可以脱离PC机，只通过手机上的巡检APP处理所有的巡检业务。</span>
          </div>
          <div style="display:flex;flex-wrap: wrap;justify-content: space-around;">
            <div>
              <img src="@/assets/app_android.png"
                   class="image"
                   style="width:170px;height:170px" />
              <div style="padding: 10px">
                <span>4G APP下载</span>
              </div>
            </div>
            <div>
              <img src="@/assets/I say.png"
                   class="image"
                   style="width:170px;height:170px" />
              <div style="padding: 10px">
                <span>4G IOS下载</span>
              </div>
            </div>
            <div>
              <img src="@/assets/api.png"
                   class="image"
                   style="width:170px;he
                   ight:170px" />
              <div style="padding: 10px">
                <span>4G API</span>
              </div>
            </div>
          </div>
          <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/实时版使用手册.pdf"
                     class="wenz">实时版使用手册</el-link>
          </div>
        </div>
      </div>

    </div>
     <el-divider></el-divider>
    <div class="new-h1">
      <div style="display: flex;
    flex-wrap: wrap;
    justify-content: space-around;">
        <el-card shadow="hover">
          <img src="@/assets/ZX G4.png"
               style="width:250px;height:220px"
               class="image" />
          <div style="padding: 20px">
            <span>ZXG4 4G版</span>
          </div>
        </el-card>
        <div style="
    background: rgba(0, 0, 0, 0);flex:1">
          <div style="padding-bottom: 20px;padding-left:20px">
            <span>巡检平台手机APP
              支持三大常用移劢平台，安卓、Hamony OS、IOS，APP端包括不WEB端除电子地图以外的全部功能。用户完合可以脱离PC机，只通过手机上的巡检APP处理所有的巡检业务。</span>
          </div>
          <div style="display:flex;flex-wrap: wrap;justify-content: space-around;">
            <div>
              <img src="@/assets/app_android.png"
                   class="image"
                   style="width:170px;height:170px" />
              <div style="padding: 10px">
                <span>4G APP下载</span>
              </div>
            </div>
            <div>
              <img src="@/assets/I say.png"
                   class="image"
                   style="width:170px;height:170px" />
              <div style="padding: 10px">
                <span>4G IOS下载</span>
              </div>
            </div>
            <div>
              <img src="@/assets/api.png"
                   class="image"
                   style="width:170px;he
                   ight:170px" />
              <div style="padding: 10px">
                <span>4G API</span>
              </div>
            </div>
          </div>
          <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/实时版使用手册.pdf"
                     class="wenz">实时版使用手册</el-link>
          </div>
        </div>
      </div>

    </div>
     <el-divider></el-divider>
    <div class="new-h1">
      <div style="display: flex;
    flex-wrap: wrap;
    justify-content: space-around;">
        <el-card shadow="hover">
          <img src="@/assets/ZX G6.png"
               style="width:250px;height:220px"
               class="image" />
          <div style="padding: 20px">
            <span>ZXG6 4G版</span>
          </div>
        </el-card>
        <div style="
    background: rgba(0, 0, 0, 0);flex:1">
          <div style="padding-bottom: 20px;padding-left:20px">
            <span>巡检平台手机APP
              支持三大常用移劢平台，安卓、Hamony OS、IOS，APP端包括不WEB端除电子地图以外的全部功能。用户完合可以脱离PC机，只通过手机上的巡检APP处理所有的巡检业务。</span>
          </div>
          <div style="display:flex;flex-wrap: wrap;justify-content: space-around;">
            <div>
              <img src="@/assets/app_android.png"
                   class="image"
                   style="width:170px;height:170px" />
              <div style="padding: 10px">
                <span>4G APP下载</span>
              </div>
            </div>
            <div>
              <img src="@/assets/I say.png"
                   class="image"
                   style="width:170px;height:170px" />
              <div style="padding: 10px">
                <span>4G IOS下载</span>
              </div>
            </div>
            <div>
              <img src="@/assets/api.png"
                   class="image"
                   style="width:170px;he
                   ight:170px" />
              <div style="padding: 10px">
                <span>4G API</span>
              </div>
            </div>
          </div>
          <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/实时版使用手册.pdf"
                     class="wenz">实时版使用手册</el-link>
          </div>
        </div>
      </div>

    </div>
     <el-divider></el-divider>
    <div class="new-h1">
      <div style="display: flex;
    flex-wrap: wrap;
    justify-content: space-around;">
        <el-card shadow="hover">
          <img src="@/assets/ZX S1.png"
               style="width:250px;height:220px"
               class="image" />
          <div style="padding: 20px">
            <span>ZXS1 单机版</span>
          </div>
        </el-card>
        <div style="
    background: rgba(0, 0, 0, 0);flex:1">
          <div style="padding-bottom: 20px;padding-left:20px">
            <span>PC端管理软件通过USB巡检设备进行通讯。包括了巡检日常工作的所有业务，含人员管理、巡检点设置、闹钟设置、线路设置、计划设置、巡检记录采集、查询、分析等。支持操作平台：Win7/Win10/Win2008等以上操作平台。</span>
          </div>
          <div style="display:flex">
            <div style="padding-left:20px">
                <el-link type="primary"
                         href="https://www.gzzxyj.com/about/zxylpatrols.zip"
                         class="wenz">巡更管理系统单机版V6.0</el-link>
              </div>          
               
          </div>
          <div style="display:flex">
                    
              <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/智小巡USB版使用手册.pdf"
                     class="wenz">智小巡USB版使用手册</el-link>
          </div>
          </div>
          <div style="display:flex">
            <div style="padding-left:20px">
                <el-link type="primary"
                         href="https://www.gzzxyj.com/about/zxylpatrolnet.zip"
                         class="wenz">智小巡巡更管理系统网络版V7.0</el-link>
              </div>                    
          </div>
          <div style="display:flex">
             
                    <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/智小巡SDK测试工具.zip"
                     class="wenz">SDK及测试工具</el-link>
          </div>
          </div>
                        
              
        </div>
      </div>
    </div>
     <el-divider></el-divider>
        <div class="new-h1">
      <div style="display: flex;
    flex-wrap: wrap;
    justify-content: space-around;">
        <el-card shadow="hover">
          <img src="@/assets/ZX S2.png"
               style="width:250px;height:220px"
               class="image" />
          <div style="padding: 20px">
            <span>ZXS2 单机版</span>
          </div>
        </el-card>
        <div style="
    background: rgba(0, 0, 0, 0);flex:1">
          <div style="padding-bottom: 20px;padding-left:20px">
            <span>PC端管理软件通过USB巡检设备进行通讯。包括了巡检日常工作的所有业务，含人员管理、巡检点设置、闹钟设置、线路设置、计划设置、巡检记录采集、查询、分析等。支持操作平台：Win7/Win10/Win2008等以上操作平台。</span>
          </div>
          <div style="display:flex">
            <div style="padding-left:20px">
                <el-link type="primary"
                         href="https://www.gzzxyj.com/about/zxylpatrols.zip"
                         class="wenz">巡更管理系统单机版V6.0</el-link>
              </div>          
            
          </div>
          <div style="display:flex">
                  
              <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/智小巡USB版使用手册.pdf"
                     class="wenz">智小巡USB版使用手册</el-link>
          </div>
          </div>
          <div style="display:flex">
            <div style="padding-left:20px">
                <el-link type="primary"
                         href="https://www.gzzxyj.com/about/zxylpatrolnet.zip"
                         class="wenz">智小巡巡更管理系统网络版V7.0</el-link>
              </div>
                     
          </div>
          <div style="display:flex">
            
                    <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/智小巡SDK测试工具.zip"
                     class="wenz">SDK及测试工具</el-link>
          </div>
          </div>              
              
        </div>
      </div>
    </div>
     <el-divider></el-divider>
            <div class="new-h1">
      <div style="display: flex;
    flex-wrap: wrap;
    justify-content: space-around;">
        <el-card shadow="hover">
          <img src="@/assets/ZX S5.png"
               style="width:250px;height:220px"
               class="image" />
          <div style="padding: 20px">
            <span>ZXS5 单机版</span>
          </div>
        </el-card>
        <div style="
    background: rgba(0, 0, 0, 0);flex:1">
          <div style="padding-bottom: 20px;padding-left:20px">
            <span>PC端管理软件通过USB巡检设备进行通讯。包括了巡检日常工作的所有业务，含人员管理、巡检点设置、闹钟设置、线路设置、计划设置、巡检记录采集、查询、分析等。支持操作平台：Win7/Win10/Win2008等以上操作平台。</span>
          </div>
          <div style="display:flex">
            <div style="padding-left:20px">
                <el-link type="primary"
                         href="https://www.gzzxyj.com/about/zxylpatrols.zip"
                         class="wenz">巡更管理系统单机版V6.0</el-link>
              </div>         
               
          </div>
          <div style="display:flex">
                   
              <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/智小巡USB版使用手册.pdf"
                     class="wenz">智小巡USB版使用手册</el-link>
          </div>
          </div>
          <div style="display:flex">
            <div style="padding-left:20px">
                <el-link type="primary"
                         href="https://www.gzzxyj.com/about/zxylpatrolnet.zip"
                         class="wenz">智小巡巡更管理系统网络版V7.0</el-link>
              </div>
                 
          </div>
          <div style="display:flex">
                    <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/智小巡SDK测试工具.zip"
                     class="wenz">SDK及测试工具</el-link>
          </div>
          </div>
        </div>
      </div>
    </div>
    </el-tab-pane>
    <el-tab-pane label="ZXG3 4G版">
      <div class="new-h1">
      <div style="display: flex;
    flex-wrap: wrap;
    justify-content: space-around;">
        <el-card shadow="hover">
          <img src="@/assets/ZX G3.png"
               style="width:250px;height:220px"
               class="image" />
          <div style="padding: 20px">
            <span>ZXG3 4G版</span>
          </div>
        </el-card>
        <div style="
    background: rgba(0, 0, 0, 0);flex:1">
          <div style="padding-bottom: 20px;padding-left:20px">
            <span>巡检平台手机APP
              支持三大常用移劢平台，安卓、Hamony OS、IOS，APP端包括不WEB端除电子地图以外的全部功能。用户完合可以脱离PC机，只通过手机上的巡检APP处理所有的巡检业务。</span>
          </div>
          <div style="display:flex;flex-wrap: wrap;justify-content: space-around;">
            <div>
              <img src="@/assets/app_android.png"
                   class="image"
                   style="width:170px;height:170px" />
              <div style="padding: 10px">
                <span>4G APP下载</span>
              </div>
            </div>
            <div>
              <img src="@/assets/I say.png"
                   class="image"
                   style="width:170px;height:170px" />
              <div style="padding: 10px">
                <span>4G IOS下载</span>
              </div>
            </div>
            <div>
              <img src="@/assets/api.png"
                   class="image"
                   style="width:170px;he
                   ight:170px" />
              <div style="padding: 10px">
                <span>4G API</span>
              </div>
            </div>
          </div>
          <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/实时版使用手册.pdf"
                     class="wenz">实时版使用手册</el-link>
          </div>
        </div>
      </div>

    </div>
    </el-tab-pane>
    <el-tab-pane label="ZXG4 4G版">
       <div class="new-h1">
      <div style="display: flex;
    flex-wrap: wrap;
    justify-content: space-around;">
        <el-card shadow="hover">
          <img src="@/assets/ZX G4.png"
               style="width:250px;height:220px"
               class="image" />
          <div style="padding: 20px">
            <span>ZXG4 4G版</span>
          </div>
        </el-card>
        <div style="
    background: rgba(0, 0, 0, 0);flex:1">
          <div style="padding-bottom: 20px;padding-left:20px">
            <span>巡检平台手机APP
              支持三大常用移劢平台，安卓、Hamony OS、IOS，APP端包括不WEB端除电子地图以外的全部功能。用户完合可以脱离PC机，只通过手机上的巡检APP处理所有的巡检业务。</span>
          </div>
          <div style="display:flex;flex-wrap: wrap;justify-content: space-around;">
            <div>
              <img src="@/assets/app_android.png"
                   class="image"
                   style="width:170px;height:170px" />
              <div style="padding: 10px">
                <span>4G APP下载</span>
              </div>
            </div>
            <div>
              <img src="@/assets/I say.png"
                   class="image"
                   style="width:170px;height:170px" />
              <div style="padding: 10px">
                <span>4G IOS下载</span>
              </div>
            </div>
            <div>
              <img src="@/assets/api.png"
                   class="image"
                   style="width:170px;he
                   ight:170px" />
              <div style="padding: 10px">
                <span>4G API</span>
              </div>
            </div>
          </div>
          <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/实时版使用手册.pdf"
                     class="wenz">实时版使用手册</el-link>
          </div>
        </div>
      </div>

    </div>
    </el-tab-pane>
    <el-tab-pane label="ZXG6 4G版">
       <div class="new-h1">
      <div style="display: flex;
    flex-wrap: wrap;
    justify-content: space-around;">
        <el-card shadow="hover">
          <img src="@/assets/ZX G6.png"
               style="width:250px;height:220px"
               class="image" />
          <div style="padding: 20px">
            <span>ZXG6 4G版</span>
          </div>
        </el-card>
        <div style="  background: rgba(0, 0, 0, 0);flex:1">
          <div style="padding-bottom: 20px;padding-left:20px">
            <span>巡检平台手机APP
              支持三大常用移劢平台，安卓、Hamony OS、IOS，APP端包括不WEB端除电子地图以外的全部功能。用户完合可以脱离PC机，只通过手机上的巡检APP处理所有的巡检业务。</span>
          </div>
          <div style="display:flex;flex-wrap: wrap;justify-content: space-around;">
            <div>
              <img src="@/assets/app_android.png"
                   class="image"
                   style="width:170px;height:170px" />
              <div style="padding: 10px">
                <span>4G APP下载</span>
              </div>
            </div>
            <div>
              <img src="@/assets/I say.png"
                   class="image"
                   style="width:170px;height:170px" />
              <div style="padding: 10px">
                <span>4G IOS下载</span>
              </div>
            </div>
            <div>
              <img src="@/assets/api.png"
                   class="image"
                   style="width:170px;he
                   ight:170px" />
              <div style="padding: 10px">
                <span>4G API</span>
              </div>
            </div>
          </div>
          <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/实时版使用手册.pdf"
                     class="wenz">实时版使用手册</el-link>
          </div>
        </div>
      </div>

    </div>
    </el-tab-pane>
    <el-tab-pane label="ZXS1 单机版">
      <div class="new-h1">
      <div style="display: flex;
    flex-wrap: wrap;
    justify-content: space-around;">
        <el-card shadow="hover">
          <img src="@/assets/ZX S1.png"
               style="width:250px;height:220px"
               class="image" />
          <div style="padding: 20px">
            <span>ZXS1 单机版</span>
          </div>
        </el-card>
        <div style="
    background: rgba(0, 0, 0, 0);flex:1">
          <div style="padding-bottom: 20px;padding-left:20px">
            <span>PC端管理软件通过USB巡检设备进行通讯。包括了巡检日常工作的所有业务，含人员管理、巡检点设置、闹钟设置、线路设置、计划设置、巡检记录采集、查询、分析等。支持操作平台：Win7/Win10/Win2008等以上操作平台。</span>
          </div>
          <div style="display:flex">
            <div style="padding-left:20px">
                <el-link type="primary"
                         href="https://www.gzzxyj.com/about/zxylpatrols.zip"
                         class="wenz">巡更管理系统单机版V6.0</el-link>
              </div>         
          </div>  
          <div style="display:flex">
                  
              <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/智小巡USB版使用手册.pdf"
                     class="wenz">智小巡USB版使用手册</el-link>
          </div>
          </div>
          <div style="display:flex">
            <div style="padding-left:20px">
                <el-link type="primary"
                         href="https://www.gzzxyj.com/about/zxylpatrolnet.zip"
                         class="wenz">智小巡巡更管理系统网络版V7.0</el-link>
              </div>                
          </div>
          <div style="display:flex">
            
                    <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/智小巡SDK测试工具.zip"
                     class="wenz">SDK及测试工具</el-link>
          </div>
          </div>               
              
        </div>
        
      </div>
    </div>
    </el-tab-pane> 
    
    <el-tab-pane label="ZXS2 单机版">
      <div class="new-h1">
      <div style="display: flex;
    flex-wrap: wrap;
    justify-content: space-around;">
        <el-card shadow="hover">
          <img src="@/assets/ZX S2.png"
               style="width:250px;height:220px"
               class="image" />
          <div style="padding: 20px">
            <span>ZXS2 单机版</span>
          </div>
        </el-card>
        <div style="
    background: rgba(0, 0, 0, 0);flex:1">
          <div style="padding-bottom: 20px;padding-left:20px">
            <span>PC端管理软件通过USB巡检设备进行通讯。包括了巡检日常工作的所有业务，含人员管理、巡检点设置、闹钟设置、线路设置、计划设置、巡检记录采集、查询、分析等。支持操作平台：Win7/Win10/Win2008等以上操作平台。</span>
          </div>
          <div style="display:flex">
            <div style="padding-left:20px">
                <el-link type="primary"
                         href="https://www.gzzxyj.com/about/zxylpatrols.zip"
                         class="wenz">巡更管理系统单机版V6.0</el-link>
              </div>          
           </div>  
          <div style="display:flex">                   
              <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/智小巡USB版使用手册.pdf"
                     class="wenz">智小巡USB版使用手册</el-link>
          </div> 
          </div>
          <div style="display:flex">
            <div style="padding-left:20px">
                <el-link type="primary"
                         href="https://www.gzzxyj.com/about/zxylpatrolnet.zip"
                         class="wenz">智小巡巡更管理系统网络版V7.0</el-link>
              </div>                  
          </div>
          <div style="display:flex">            
                    <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/智小巡SDK测试工具.zip"
                     class="wenz">SDK及测试工具</el-link>
          </div>
          </div>
        </div>
      </div>
      
    </div>
    </el-tab-pane>
    <el-tab-pane label="ZXS5 单机版">
      <div class="new-h1">
      <div style="display: flex;
    flex-wrap: wrap;
    justify-content: space-around;">
        <el-card shadow="hover">
          <img src="@/assets/ZX S5.png"
               style="width:250px;height:220px"
               class="image" />
          <div style="padding: 20px">
            <span>ZXS5 单机版</span>
          </div>
        </el-card>
        <div style="
    background: rgba(0, 0, 0, 0);flex:1">
          <div style="padding-bottom: 20px;padding-left:20px">
            <span>PC端管理软件通过USB巡检设备进行通讯。包括了巡检日常工作的所有业务，含人员管理、巡检点设置、闹钟设置、线路设置、计划设置、巡检记录采集、查询、分析等。支持操作平台：Win7/Win10/Win2008等以上操作平台。</span>
          </div>
          <div style="display:flex">
            <div style="padding-left:20px">
                <el-link type="primary"
                         href="https://www.gzzxyj.com/about/zxylpatrols.zip"
                         class="wenz">巡更管理系统单机版V6.0</el-link>
              </div>          
          </div>   
          <div style="display:flex">
                  
              <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/智小巡USB版使用手册.pdf"
                     class="wenz">智小巡USB版使用手册</el-link>
          </div>
          </div>
          <div style="display:flex">
             
                    <div style="padding-left:20px">
            <el-link type="primary"
                         href="https://www.gzzxyj.com/about/zxylpatrolnet.zip"
                         class="wenz">智小巡巡更管理系统网络版V7.0</el-link>
          </div>
          </div>
          <div style="display:flex">
             
                    <div style="padding-left:20px">
            <el-link type="primary"
                     href="https://www.gzzxyj.com/about/智小巡SDK测试工具.zip"
                     class="wenz">SDK及测试工具</el-link>
          </div>
          </div>
        </div>
      </div>
    </div>
    </el-tab-pane>

  </el-tabs>
  </div>
</template>
<script>
export default {
  name: "DownLoad",
  components: {},
  data () {
    return {
      url: require("@/assets/bghelp.jpg"),
      // activeNames: ["1",'2','3','4','5','6'],
    };
  },
};
</script>
<style lang="less" scoped>
.downloadxx {
  .el-col {
    padding-bottom: 20px;
  }
  .el-card {
    height: 320px;
    width: 300px;
    // img {
    //   height: 250px;
    //   width: 250px;
    // }
  }
  .wenz {
    font-size: 20px;
    line-height: 40px;
    // color: black;
    font-weight: 500;
  }
}
</style>
