<template>
  <div>
    <!-- <el-row :gutter="10">
      <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="12">
        <div class="grid-content bg-purple-light">
          <div style="text-align: center; margin: 25px 0">
            <span class="span_h1">云巡更行业应用</span>
          </div>
          <p>一家提供专业软/硬件一体化解决方案的科技企业。</p>
          <p>
            云巡更管理能有效地监督和管理巡逻情况，准确地规划和记录巡逻人员的巡逻时间、次数及线路，依托计算机信息网络，做到资源共享，是加强对巡逻防范工作的有利载体。
          </p>
          <router-link :to="{ name: 'SoluTionOne' }"><el-button>了解更多</el-button></router-link>
        </div>
      </el-col>
      <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="12" align="right">
        <div>
          <div style="margin-top: 10px">
            <img style="width: 100%; height: 100%" src="@/assets/bg2.jpg" />
          </div>
        </div>
      </el-col>
    </el-row> -->
    <!-- <div style=" margin-top: 20px;width:100%;height:100%"
         class="new-h1">
      <div class="title">云巡更行业应用</div>
      <el-divider>一家提供专业软/硬件一体化解决方案的科技企业</el-divider>
      <el-row :gutter="10">
        <el-col :xs="12"
                :sm="12"
                :md="12"
                :lg="6"
                :xl="12"
                style="padding:2em">
          <div style="font-size:1.1em;text-indent:35px;line-height:1.2em;    padding-top: 2em">
            云巡更管理能有效地监督和管理巡逻情况，准确地规划和记录巡逻人员的巡逻时间、次数及线路，依托计算机信息网络，做到资源共享，是加强对巡逻防范工作的有利载体。
          </div>
          <br>
          <router-link :to="{ name: 'SoluTionOne' }">
            <el-button type="primary">了解更多</el-button>
          </router-link>
        </el-col>
        <el-col :xs="12"
                :sm="12"
                :md="12"
                :lg="6"
                :xl="12"
                align="right"
                style="padding:30px">
          <div>
            <img style="width: 100%; height: 100%"
                 src="@/assets/bg2.jpg" />
          </div>

        </el-col>
      </el-row>
    </div> -->
    <div style="text-align: center; margin-top: 20px"
         class="new-h1">
      <span class="title">解决方案</span>
      <el-divider>
        本公司可为各行业客户提供个性化定制解决方案和实施服务
      </el-divider>
      <p style="margin: 20px 10%;font-size: 1.2em;padding-top: 2em">本公司结合自有技术和市场成熟产品，为各行业客户提供个性化定制解决方案和实施服务。</p>
      <el-row :gutter="10">
        <el-col :xs="24"
                :sm="12"
                :md="12"
                :lg="6"
                :xl="6">
          <router-link :to="{ name: 'SoluTionOne' }">
            <el-card shadow="hover">
              <img src="@/assets/s2.png"
                   class="image" />
              <div style="padding: 14px">
                <span>云巡更解决方案</span>
                <div class="bottom clearfix">
                  <el-button type="text"
                             class="button">查看详情</el-button>
                </div>
              </div>
            </el-card>
          </router-link>
        </el-col>
        <el-col :xs="24"
                :sm="12"
                :md="12"
                :lg="6"
                :xl="6">
          <router-link :to="{ name: 'SoluTionTwo' }">
            <el-card shadow="hover">
              <img src="@/assets/p14.png"
                   class="image" />
              <div style="padding: 14px">
                <span>园区/校企安保巡更应用方案</span>
                <div class="bottom clearfix">
                  <el-button type="text"
                             class="button">查看详情</el-button>
                </div>
              </div>
            </el-card>
          </router-link>
        </el-col>
        <el-col :xs="24"
                :sm="12"
                :md="12"
                :lg="6"
                :xl="6">
          <router-link :to="{ name: 'SoluTionThree' }">
            <el-card shadow="hover">
              <img src="@/assets/p1.png"
                   class="image" />
              <div style="padding: 14px">
                <span>智能电网GPS巡更应用方案</span>
                <div class="bottom clearfix">
                  <el-button type="text"
                             class="button">查看详情</el-button>
                </div>
              </div>
            </el-card>
          </router-link>
        </el-col>
        <el-col :xs="24"
                :sm="12"
                :md="12"
                :lg="6"
                :xl="6">
          <router-link :to="{ name: 'SoluTionFour' }">
            <el-card shadow="hover">
              <img src="@/assets/p7.png"
                   class="image" />
              <div style="padding: 14px">
                <span>自来水厂智能巡更应用方案</span>
                <div class="bottom clearfix">
                  <el-button type="text"
                             class="button">查看详情</el-button>
                </div>
              </div>
            </el-card>
          </router-link>
        </el-col>
      </el-row>
    </div>
    <div style="text-align: center; margin-top: 20px"
         class="new-h1">
      <span class="title">产品中心</span>
      <el-divider>云巡更管理是加强对巡逻防范工作的有利载体</el-divider>
      <p style="margin: 20px 10%; font-size: 1.2em;    padding-top: 2em">
        云巡更管理能有效地监督和管理巡逻情况，准确地规划和记录巡逻人员的巡逻时间、次数及线路，依托计算机信息网络，做到资源共享，是加强对巡逻防范工作的有利载体。
      </p>
      <div class="zpzz">
        <el-row :gutter="10">
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="8">
            <router-link :to="{ name: 'ProDuctTwo' }"
                         class="aaa">
              <el-card shadow="hover">
                <img src="@/assets/ZX G3.png"
                     style="width: 250px"
                     class="image" />
                <div style="padding: 14px">
                  <span>ZXG3 4G版</span>
                  <div class="bottom clearfix">
                    <el-button type="text"
                               class="button">查看详情</el-button>
                  </div>
                </div>
              </el-card>
            </router-link>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="8">
            <router-link :to="{ name: 'ProDuctOne'  }"
                         class="aaa">
              <el-card shadow="hover">
                <img src="@/assets/ZX S2.png"
                     style="width: 250px"
                     class="image" />
                <div style="padding: 14px">
                  <span>ZXS2 单机版</span>
                  <div class="bottom clearfix">
                    <el-button type="text"
                               class="button">查看详情</el-button>
                  </div>
                </div>
              </el-card>
            </router-link>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                  :xl="8">
            <router-link :to="{ name: 'ProDuctTwo' }"
                         class="aaa">
              <el-card shadow="hover">
                <img src="@/assets/ZX G6.png"
                     style="width: 250px"
                     class="image" />
                <div style="padding: 14px">
                  <span>ZXG6 4G版</span>
                  <div class="bottom clearfix">
                    <el-button type="text"
                               class="button">查看详情</el-button>
                  </div>
                </div>
              </el-card>
            </router-link>
          </el-col>
        </el-row>
      </div>
    </div>
    <div style="text-align: center; margin-top: 20px"
         class="new-h1">
      <span class="title">技术支持</span>
      <el-divider>单机版客户端下载、WEB端用户手册、了解全部型号</el-divider>
      <p style="margin: 20px 10%; font-size: 1.2em;    padding-top: 2em">
        云巡更管理能有效地监督和管理巡逻情况，准确地规划和记录巡逻人员的巡逻时间、次数及线路，依托计算机信息网络，做到资源共享，是加强对巡逻防范工作的有利载体。
      </p>
                  <router-link :to="{ name: 'DownLoad' }">
              <el-button>了解更多</el-button>
            </router-link>
      <!-- <div class="cpzx">
        <el-row :gutter="10">
          <el-col :xs="24"
                  :sm="12"
                  :md="6"
                  :lg="6"
                  :xl="6">
            <router-link :to="{ name: 'DownLoad' }"
                         class="bbb">
              <el-card shadow="hover">
                <img src="@/assets/ZX G3.png"
                     style="width:250px;height:200px"
                     class="image" />
                <div style="padding: 14px">
                  <span>ZXG3 4G版</span>
                  <div class="bottom clearfix">
                    <el-button type="text"
                               class="button">查看详情</el-button>
                  </div>
                </div>
              </el-card>
            </router-link>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="6"
                  :lg="6"
                  :xl="6">
            <router-link :to="{ name: 'DownLoad' }"
                         class="bbb">
              <el-card shadow="hover">
                <img src="@/assets/ZX G4.png"
                     style="width:250px;height:200px"
                     class="image" />
                <div style="padding: 14px">
                  <span>ZXG4 4G版</span>
                  <div class="bottom clearfix">
                    <el-button type="text"
                               class="button">查看详情</el-button>
                  </div>
                </div>
              </el-card>
            </router-link>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="6"
                  :lg="6"
                  :xl="6">
            <router-link :to="{ name: 'DownLoad' }"
                         class="bbb">
              <el-card shadow="hover">
                <img src="@/assets/ZX G6.png"
                     style="width:250px;height:200px"
                     class="image" />
                <div style="padding: 14px">
                  <span>ZXG6 4G版</span>
                  <div class="bottom clearfix">
                    <el-button type="text"
                               class="button">查看详情</el-button>
                  </div>
                </div>
              </el-card>
            </router-link>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  :md="6"
                  :lg="6"
                  :xl="6">
            <router-link :to="{ name: 'DownLoad' }"
                         class="bbb">
              <el-card shadow="hover">
                <img src="@/assets/ZX S1.png"
                     style="width:250px;height:200px"
                     class="image" />
                <div style="padding: 14px">
                  <span>ZXS1 单机版</span>
                  <div class="bottom clearfix">
                    <el-button type="text"
                               class="button">查看详情</el-button>
                  </div>
                </div>
              </el-card>
            </router-link>
          </el-col>
        </el-row>
      </div> -->
    </div>
    <div style="text-align: center">
      <el-row :gutter="10">
        <el-col :xs="1"
                :sm="1"
                :md="1"
                :lg="1"
                :xl="1">
          <div style="width: 100%; background: rgb(0 0 0 / 0%); height: 10px"></div>
        </el-col>
        <el-col :xs="22"
                :sm="12"
                :md="12"
                :lg="12"
                :xl="12">
          <div class="grid-content bg-purple-light new-h1">
            <div class="small"
                 style="text-align: center; margin: 25px 0">
              <span class="title">关于我们</span>
              <el-divider>提供专业软/硬件一体化解决方案的科技企业</el-divider>
            </div>
            <div style="text-align: left;    padding-top: 2em">
              <p style="text-indent:35px;">
                我司提供专业的智慧巡检应用解决方案，广泛应用政府企事业单位、园区、校园、工厂企业；提供传统保安巡更、电力巡检、水务管道巡检、公安巡逻、APP巡检等多种应用场景解决方案。
              </p>
              <p style="text-indent:35px;">
                我司自主研发的巡更产品系列：传统巡更棒、4G实时巡棒、GPS巡更棒、PAD巡更棒。并提供各种智慧巡检方案定制。
              </p>
            </div>
            <router-link :to="{ name: 'AboutView' }">
              <el-button>了解更多</el-button>
            </router-link>
          </div>
        </el-col>
        <el-col :xs="22"
                :sm="11"
                :md="6"
                :lg="6"
                :xl="6"
                align="right">
          <router-link :to="{ name: 'AboutView' }">
            <div>
              <div style="width: 20em;position: relative;">
                <div style="width: 100%;height: 100%;position: absolute;background:rgb(6 6 51 / 31%)"></div>
                <p style="position: absolute;color: white;top:30%;bottom:30%;font-size: 2em;left: 20%;right: 20%;">企业概况<i class="el-icon-caret-right"></i></p>
                <img style="width: 100%; height: 100%"
                     src="@/assets/bg11.jpg" />
              </div>
            </div>
          </router-link>
        </el-col>
      </el-row>
    </div>
    <div style=" margin-top: 20px;width:100%;height:100%;margin-bottom:0"
         class="new-h1  hidden-md-and-down">
      <div class="title">企业资质证书</div>
      <el-divider>提供专业软/硬件一体化解决方案</el-divider>
      <el-carousel :interval="5000"
                   type="card"
                   width="50px"
                   height="500px">
        <el-carousel-item v-for="(item, index) in images"
                          :key="index">
          <h3 style="position: fixed; top: 0; left: 1%; z-index: 1; background: rgb(255 101 55 / 80%); color: #fff; border-radius: 10px; padding: 10px; font-size: 1rem; font-weight: 500; text-align: center;">
            <span style="font-size:16px">
              {{ item.introduce }}
            </span>
          </h3>
          <el-image :class="className"
                    style="width: 100%; height: 100%"
                    :src="item.idView"
                    fit="fill"  :preview-src-list="srcList" ref="preview">
          </el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div style=" margin-top: 20px;width:100%;height:100%;margin-bottom:0"
         class="new-h1  hidden-lg-and-up hidden-xs-only">
      <div class="title">企业资质证书</div>
      <el-divider>提供专业软/硬件一体化解决方案</el-divider>
      <el-carousel :interval="5000"
                   type="card"
                   width="50px"
                   height="500px">
        <el-carousel-item v-for="(item, index) in images"
                          :key="index">
          <h3 style="position: fixed; top: 0; left: 0; z-index: 1; background: rgb(255 101 55 / 80%); color: #fff; border-radius: 10px; padding: 10px; font-size: 1rem; font-weight: 500; text-align: center;">
            <span style="font-size:16px">
              {{ item.introduce }}
            </span>
          </h3>
          <el-image :class="className"
                    style="width: 100%; height: 100%"
                    :src="item.idView" :preview-src-list="srcList" ref="preview"
                    fit="fill">
          </el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div style=" margin-top: 20px;width:100%;height:100%;margin-bottom:0"
         class="new-h1  hidden-sm-and-up">
      <div class="title">企业资质证书</div>
      <el-divider>提供专业软/硬件一体化解决方案</el-divider>
      <el-carousel :interval="5000"
                   width="50px"
                   height="80vh">
        <el-carousel-item v-for="(item, index) in images"
                          :key="index">
          <h3 style="position: fixed; top: 0; left: 0; z-index: 1; background: rgb(255 101 55 / 80%); color: #fff; border-radius: 10px; padding: 10px; font-size: 1rem; font-weight: 500; text-align: center;">
            <span style="font-size:16px">
              {{ item.introduce }}
            </span>
          </h3>
          <el-image :class="className"
                    style="width: 100%; height: 100%"
                    :src="item.idView"
                    fit="fill"  :preview-src-list="srcList" ref="preview">
          </el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      url: "../../assets/bg2.jpg",
      srcList:[
        require("@/assets/商标证书.jpg"),
        require("@/assets/巡检IOS.jpg"),
        require("@/assets/巡检安卓.jpg"),
        // require("@/assets/云巡检WEB端.jpg"),
      ],
      images: [
        // {
        //   id: 0,
        //   idView: require("@/assets/商标证书.jpg"),
        //   introduce: "商标证书",
        // },
        {
          id: 1,
          idView: require("@/assets/商标证书2.jpg"),
          introduce: "商标证书",
        },
        // {
        //   id: 2,
        //   idView: require("@/assets/巡检IOS.jpg"),
        //   introduce: "巡检IOS证书",
        // }, 
        {
          id: 3,
          idView: require("@/assets/巡检IOS2.jpg"),
          introduce: "巡检IOS证书",
        }, 
        // {
        //   id: 4,
        //   idView: require("@/assets/巡检安卓.jpg"),
        //   introduce: "巡检安卓证书",
        // }, 
        {
          id: 5,
          idView: require("@/assets/巡检安卓2.jpg"),
          introduce: "巡检安卓证书",
        }, 
        // {
        //   id: 6,
        //   idView: require("@/assets/云巡检WEB端.jpg"),
        //   introduce: "云巡检WEB端证书",
        // }, 
        {
          id: 7,
          idView: require("@/assets/云巡检WEB端2.jpg"),
          introduce: "云巡检WEB端证书",
        },
      ],
      className: "",
    };
  },
  methods: {
        onPreview() {
          this.$refs.preview.clickHandler()
        },
    }
};
</script>
<style lang="less">

.cpzx {
  .el-row {
    .el-col {
      border-radius: 4px;
      .bbb {
        div {
          // height: 220px;
          height: 350px;
          img {
            width: 100%;
            // width: 100%;
          }
        }
      }
    }
  }
}
.el-col {
  border-radius: 4px;
  a {
    div {
      height: 350px;
      img {
        width: 100%;
        height:70%
        // width: 100%;
      }
    }
  }
  .aaa{
        div {
      height: 300px;
      img {
        width: 100%;
        height:70%
        // width: 100%;
      }
    }
  }
}
//  .el-carousel__item h3 {
//     color: #475669;
//     font-size: 14px;
//     opacity: 0.75;
//     line-height: 200px;
//     margin: 0;
//   }

//   .el-carousel__item:nth-child(2n) {
//     background-color: #99a9bf;
//   }

//   .el-carousel__item:nth-child(2n+1) {
//     background-color: #d3dce6;
//   }
/* .bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
} */
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.new-h1 {
  margin-bottom: 2em;
  .el-divider__text {
    color: #888;
    font-size: 1em;
    line-height: 1.2em;
  }
  .title {
    text-align: center;
    line-height: 80px;
    font-size: 30px;
    color: #ff6537;
    font-weight: bolder;
  }
  p {
    text-indent: 3em;
  }
  .el-carousel {
    
    .el-carousel__item:nth-child(2n) {
        background-color: #3b7edb;
    }
    .el-carousel__item:nth-child(2n+1) {
        background-color: #517cad;
    }
    .el-carousel__item--card {
    width: 352px;}
    // /deep/ .el-carousel__indicators {// 指示器
    //     left: unset;
    //     transform: unset;
    //     right: 2%;
    // }
    .el-carousel__button {// 指示器按钮
        background-color: rgb(97, 97, 97);
        opacity:.5
        
    }
    .is-active .el-carousel__button {// 指示器激活按钮
        background: #ff6537;
        opacity:1
    }
}
  // .small{
  //     .el-divider__text {
  //   color: #888;
  //   font-size: 1em;
  // }
  // }
}
.hidden-md-and-down{
.el-carousel{
  padding: 0 14%;
}
  }
.hidden-lg-and-up{
.el-carousel{
  margin: 0 15vw;
}
  }
.head{
  position: relative;
    overflow: hidden;
}
</style>