<template>
  <div>
    <div style="position: relative">
      <div id="container" />
      <div id="panel" />
    </div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
  data () {
    return {
      map: null,
    }
  },
  mounted () {
    this.handleInitMap()
    // this.handleWalk()
  },
  methods: {
    handleInitMap () {
      AMapLoader.load({
        key: '7f2c32ca69ce4f92675734388e162d2b', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        resizeEnable: true,

        plugins: [
        ] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map('container', {
          resizeEnable: true, // 是否监控地图容器尺寸变化
          viewMode: '2D', // 是否为3D地图模式
          zoom: 20, // 初始化地图级别
          center: [113.431532,23.163013],//设置地图中心点坐标
          // mapStyle: 'amap://styles/whitesmoke'
        })
        // 创建一个 Marker 实例：
        var marker = new AMap.Marker({
          position: new AMap.LngLat(113.431532,23.163013),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: '广州',
          map: this.map
        });
        // 将创建的点标记添加到已有的地图实例：
        // this.map.add(marker);
        var infoWindow = new AMap.InfoWindow({
          anchor: 'top-center',
          content: "广州智享云联科技有限公司",//信息窗体的内容
        });

        //如果希望的是点击标记才 出现这个信息窗口，那把 下面的注释去掉即可
        // AMap.event.addListener(marker,'click',function(){ //监听点标记的点击事件
        infoWindow.open(this.map, marker.getPosition()); //信息窗体打开
      });
      // })
    },
    //在指定位置打开信息窗体
    openInfo () {
      AMapLoader.load({
        key: '7f2c32ca69ce4f92675734388e162d2b', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        resizeEnable: true,

        plugins: [
        ] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        var infoWindow;
        var map = new AMap.Map("container", {
          resizeEnable: true,
          center: [113.27143134, 23.13533631],
          zoom: 26
        });

        //构建信息窗体中显示的内容
        var info = [];
        info.push("<div class='input-card content-window-card'><div><img style='float:left;width:67px;height:16px;' src=' https://webapi.amap.com/images/autonavi.png '/></div> ");
        info.push("<div style='padding:7px 0px 0px 0px;'><h4>高德软件</h4>");
        info.push("<p class='input-item'>电话 : 010-84107000   邮编 : 100102</p>");
        info.push("<p class='input-item'>地址 :北京市朝阳区望京阜荣街10号首开广场4层</p></div></div>");

        infoWindow = new AMap.InfoWindow({
          content: info.join("")  //使用默认信息窗体框样式，显示信息内容
        });

        infoWindow.open(map, map.getCenter());
      })
    },
    handleAddMarker () {
      // 构造点标记
      AMapLoader.load({
        key: '7f2c32ca69ce4f92675734388e162d2b', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        resizeEnable: true,

        plugins: [] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        const marker = new AMap.Marker({
          // icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
          position: this.lnglat
        })
        // 构造矢量圆形
        const circle = new AMap.Circle({
          center: new AMap.LngLat(this.lnglat[0], this.lnglat[1]), // 圆心位置
          radius: 5, // 半径
          strokeColor: '#1890ff', // 线颜色
          strokeOpacity: 1, // 线透明度
          strokeWeight: 3, // 线粗细度
          fillColor: '#1890ff', // 填充颜色
          fillOpacity: 0.35 // 填充透明度
        })

        // 将以上覆盖物添加到地图上
        // 单独将点标记添加到地图上
        this.map.add(marker)
        // add方法可以传入一个覆盖物数组，将点标记和矢量圆同时添加到地图上
        this.map.add([marker, circle])
      })
    },
    handleRemove () {
      // 使用clearMap方法删除所有覆盖物
      this.dynamicTags = []
      this.value = ''
      this.map.clearMap()
      this.radio = '1'
      this.handleInitMap()
      this.walking.clear()
      var panel = document.getElementById('panel')
      panel.style.display = 'none'
    },
    handleClose (tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
    },
    // showInput() {
    //   this.inputVisible = true;
    //   this.$nextTick((_) => {
    //     this.$refs.saveTagInput.$refs.input.focus();
    //   });
    // },

    handleInputConfirm () {
      const inputValue = this.inputValue || this.lnglatl
      if (inputValue) {
        var values = inputValue.split(/[ \n]/).filter((item) => {
          return item !== '' && item !== undefined
        })
        values.forEach((element) => {
          var index = this.dynamicTags.findIndex((i) => {
            return i === element
          })
          if (index < 0) {
            this.dynamicTags.push(element)
          }
        })
      }
      this.inputVisible = false
      this.inputValue = ''
    }
  }
}
</script>
<style lang="scss" scoped>
#container {
  width: 700px;
  height: 700px;
}
.input-card {
  width: 150px;
  top: 10px;
  left: 10px;
  bottom: auto;
}
.wrap {
  position: relative;
  #pickerInput {
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 99999;
  }
}
#panel {
  position: absolute;
  background-color: white;
  max-height: 90%;
  overflow-y: auto;
  top: 10px;
  left: 10px;
  width: 280px;
}
#search {
  position: absolute;
  width: 200px;
  height: 100px;
  top: 10px;
  left: 10px;
  background-color: #fff;
}
#panel .amap-call {
  background-color: #009cf9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
#panel .amap-lib-walking {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}
</style>
<style>
.el-tag {
  margin-right: 10px;
}
.el-tag + .el-tag {
  margin-right: 10px;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  vertical-align: bottom;
}
.custom-input-card {
  width: 18rem;
}

.custom-input-card .btn:last-child {
  margin-left: 1rem;
}

.content-window-card {
  position: relative;
  width: 23rem;
  padding: 0.75rem 0 0 1.25rem;
  box-shadow: none;
  bottom: 0;
  left: 0;
}

.content-window-card p {
  height: 2rem;
}
.amap-container img{
  height:32px
}
</style>

