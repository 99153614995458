import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import SoluTionOne from '@/views/SolutionPage/SoluTionOne.vue'
import SoluTionTwo from '@/views/SolutionPage/SoluTionTwo.vue'
import DownLoad from '@/views/DownLoad'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'AboutView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView
  },
  {
    path: '/download',
    name: 'DownLoad',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: DownLoad
  },
  // {
  //   path: '/solution',
  //   name: 'SoluTion',
  //   component: () => import('@/views/SolutionPage/SolutionView.vue'),
  //   children: [
  {
    path: '/solution/one',
    name: 'SoluTionOne',
    component: SoluTionOne,
  },
  {
    path: '/solution/two',
    name: 'SoluTionTwo',
    component: SoluTionTwo,
  },
  {
    path: '/solution/three',
    name: 'SoluTionThree',
    component: () => import('@/views/SolutionPage/SoluTionThree.vue'),
  },
  {
    path: '/solution/four',
    name: 'SoluTionFour',
    component: () => import('@/views/SolutionPage/SoluTionFour.vue'),
  },
  //   ]
  // },
  // {
  //   path: '/product',
  //   name: 'ProDuct',
  //   component: () => import('@/views/ProductPage/ProductView.vue'),
  //   children: [
  {
    path: '/product/one',
    name: 'ProDuctOne',
    component: () => import('@/views/ProductPage/ProDuctOne.vue'),
  },
  {
    path: '/product/two',
    name: 'ProDuctTwo',
    component: () => import('@/views/ProductPage/ProDuctTwo.vue'),
  },
  {
    path: '/product/three',
    name: 'ProDuctThree',
    component: () => import('@/views/ProductPage/ProDuctThree.vue'),
  },
  // ]
  // },
  // {
  //   path: '/introduce',
  //   name: 'introduce',
  //   component: () => import('@/views/IntroduceView.vue')
  // }
]

const router = new VueRouter({
  routes,
  // 对于页面跳转，全部都返回到页面顶部。
  // eslint-disable-next-line no-unused-vars

})

export default router
