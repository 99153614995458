<template>
  <div>
    <div style="{
                width: 50px;
                z-index:10;
    height: 50px;
    width: 50px;
    position: fixed;
    bottom: 35px;
    right: 16px;
    border-radius: 50%;
    background-color: rgb(242, 245, 246);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 6px;
    text-align: center;
    line-height: 60px;
    color: #ff6537;
    cursor: pointer;
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                transform: rotate(90deg);
            }"
         v-if="btnFlag"
         @click="backTop">
      <i class="el-icon-caret-left"
         style="font-size:30px"></i>
    </div>
    <div id="app">
      <el-container>
        <el-header>
          <header-nav />
        </el-header>
        <el-main style="padding: 20px 5px 20px 5px;margin: -8px;">
          <router-view />
        </el-main>

        <el-footer style="padding:0">
        <footer-nav/>
        </el-footer>
      </el-container>
    </div>
    <!-- </div> -->
  </div>

</template>
<script>
import HeaderNav from "@/components/HeaderNav";
import FooterNav from "@/components/FooterNav";
// import UpDown from "@/components/UpDown";
export default {
  components: {
    HeaderNav, FooterNav,
    // UpDown
  },
  data () {
    return {
      btnFlag: false
    };
  },

  // vue的两个生命钩子，这里不多解释。
  // window对象，所有浏览器都支持window对象。它表示浏览器窗口，监听滚动事件
  mounted () {
    window.addEventListener('scroll', this.scrollToTop)
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollToTop)
  },
  methods: {
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop () {
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },

    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop () {
      const that = this
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 10) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
    }
  }
};
</script>
<style lang="less">
// .el-header{
//   padding: 0;
// }
// button{
  .el-button{
  color:#ff6537 !important
}
// }
#app {
  color: #666;
  width: 100%;
  padding: 0;
  margin: 0;
  .el-container{
    .el-header{
padding: 0;
    margin: -8px;
      height: 150px !important;
    }
  }
}
a {
  text-decoration: none;
  padding: 10px 20px;
}
.updown {
  position: fixed;
  height: 100vh;
  overflow-x: hidden;
  width: 100%;
}
.span_h1,
.el-collapse-item__header {
  border-right: 2px solid #ff6537;
  /* margin: 0px 30%; */
  padding: 0 10px 0 0;
  font-size: 25px;
  font-weight: bold;
}
.go-top {
  display: block;
  width: 50px;
  height: 50px;
}
.el-tabs--left{
  .el-tabs__header{
    .el-tabs__nav-wrap{
.el-tabs__nav-scroll{
  .el-tabs__nav{
    .el-tabs__active-bar{
  color: #ff6537;
  background-color: #ff6537 ;      
    }
    .el-tabs__item:hover {
  color: #ff6537;
  // background-color: #ff6537 ;   
}
.el-tabs__item.is-active {
    color: #ff6537;
    // background-color: #ff6537;
} 
  }
  
}
       }
  
  }

}


// .el-card__body, .el-main {
//     padding:0;
// }
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
