<template>
  <div class="home">
    <carou-sel></carou-sel>
    <main-body></main-body>      
    <div style="height:50px"></div>
  </div>
</template>

<script>
import CarouSel from '@/components/CarouSel'
import MainBody from '@/components/MainBody'


export default {
  name: 'HomeView',
  components: {
    // HelloWorld
    CarouSel,
    MainBody,
    // SoluTion
  }
}
</script>
