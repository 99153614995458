<template>
<div style="height: 500px;">
  <el-col :xs="32" :sm="32" :md="32" :lg="32" :xl="32" style="background: rgb(4 22 50)">
    <!-- <div class="grid-content bg-purple-dark"> -->
    <el-carousel :interval="5000" arrow="always" height="500px">
      <el-carousel-item v-for="(item, index) in images" :key="index">
          <!-- <div style="display: flex; flex-direction: column;align-items: center;justify-content: center;"> -->
            <h3 class="medium">
                <span style="font-size:1.8em">
              {{ item.introduce }}
            </span>
             <el-divider></el-divider>
            <div style="font-size:1.2em">
              {{ item.ppp }}
            </div>
            </h3>
          <!-- </div> -->
        <el-image
          :class="className"
          style="width: 100%; height: 100%"
          :src="item.idView"
          fit="fill"
        >
        </el-image>
      </el-carousel-item>
    </el-carousel>
    <!-- </div> -->
  </el-col>
  </div>
</template>
<script>
export default {
  name: "CarouSel",
  data() {
    return {
      images: [
        {
          id: 0,
          idView: require("@/assets/banner1.jpg"),
          introduce: "",
          ppp: "",
        },
        {
          id: 1,
          idView: require("@/assets/banner2.jpg"),
          introduce: "",
          ppp: "",
        },
        {
          id: 2,
          idView: require("@/assets/banner3.jpg"),
          introduce: "",
          ppp: "",
        },
      ],
      className: "",
    };
  },
};
</script>
<style scoped>

.el-carousel__item h3 {
position: fixed;
    /* top:30%; */
    bottom:5%;
    left: 10%;
    right:10%;
    z-index: 1;
    background: #00000050;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
}

/* .el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
} */
</style>
<style lang="less">
// .el-carousel__container{
//   button{

//   }
// }
// .el-carousel,
// .el-carousel--horizontal {
//   .el-carousel__container {
//     height: 80vh;
//   }
// }
</style>