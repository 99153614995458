<template>
  <div>
    <el-menu :default-active="activeIndex"
             mode="horizontal"
             @select="handleSelect"
             class="el-menu-demo hidden-md-and-down"
             active-text-color="#ff6537"
             div
             style="display: flex; align-items: center;height: 224px;"
             router>
      <div style="height: 130px;padding:0 100px 0 10%">
        <router-link to="/"
                     style="font-size: 30px; color: #ff6537; padding: 0px 10px 10px 10px">
          <img src="@/assets/loginLogo2.0.png"
               style="height:60%" />
        </router-link>
      </div>
      <el-menu-item index="/" >
        <span style="
              font-size:20px;
              color: #303133;
              text-decoration: none;
              font-weight: 500;
            ">首页</span>
      </el-menu-item>
      <el-submenu index="/soution" style="padding:0 20px">
        <template slot="title">
          <span style="
              font-size:20px;
              color: #303133;
              text-decoration: none;
              font-weight: 500;
            ">解决方案</span>
        </template>
        <el-menu-item index="/solution/one">云巡更解决方案</el-menu-item>
        <el-menu-item index="/solution/two">园区/校企安保巡更应用方案</el-menu-item>
        <el-menu-item index="/solution/three">智能电网GPS巡更应用方案</el-menu-item>
        <el-menu-item index="/solution/four">自来水厂智能巡更应用方案</el-menu-item>
      </el-submenu>
      <el-submenu index="/product"  style="padding:0 20px">
        <template slot="title">
          <span style="
              font-size:20px;
              color: #303133;
              text-decoration: none;
              font-weight: 500;
            ">产品中心</span>
        </template>
        <el-menu-item index="/product/one">USB巡更棒</el-menu-item>
        <el-menu-item index="/product/two">4G巡更棒</el-menu-item>
        <!-- <el-menu-item index="/product/three">巡更点</el-menu-item> -->
      </el-submenu>
      <el-menu-item index="/download">
        <span style="
              font-size:20px;
              color: #303133;
              text-decoration: none;
              font-weight: 500;
            ">技术支持</span>
      </el-menu-item>
      <el-menu-item style="" >
        <el-link href="https://zxx.payzxyj.com"
                 style="font-size:20px;
      color: #303133;
      text-decoration: none;
      font-weight: 500;padding:0 20px;"
                 :underline="false">智小巡云平台</el-link>
      </el-menu-item>
      <el-menu-item index="/about"
                    style="font-size:20px;
      color: #303133;
      text-decoration: none;
      font-weight: 500; ">关于我们</el-menu-item>
    </el-menu>
    <el-menu default-active="1"
             class="el-menu-vertical-demo hidden-lg-and-up"
             style="color: #ff6537; z-index: 11; border-right: 1px solid #ffffff00"
             @select="handleSelect"
             router>
      <el-submenu index="/">
        <template slot="title">
          <div style="
              font-size: 20px;
              color: #ff6537;
              position: absolute;
            ">
            <!-- <router-link to="/" style="font-size: 30px; color: #ff6537; padding: 0px 10px 10px 10px"> -->
            <img src="@/assets/loginLogo2.0.png"
                 style="height:80px;padding: 20px 20px 20px 0;" />
            <!-- </router-link> -->
          </div>
        </template>
        <el-menu-item index="/">首页</el-menu-item>
        <el-submenu index="/soution" class="small">
          <template slot="title">
            <span>解决方案</span>
          </template>
          <el-menu-item index="/solution/one">云巡更解决方案</el-menu-item>
          <el-menu-item index="/solution/two">园区/校企安保巡更应用方案</el-menu-item>
          <el-menu-item index="/solution/three">智能电网GPS巡更应用方案</el-menu-item>
          <el-menu-item index="/solution/four">自来水厂智能巡更应用方案</el-menu-item>
        </el-submenu>
        <el-submenu index="/product" class="small">
          <template slot="title">
            <span>产品中心</span>
          </template>
          <el-menu-item index="/product/one">USB巡更棒</el-menu-item>
          <el-menu-item index="/product/two">4G巡更棒</el-menu-item>
          <!-- <el-menu-item index="/product/three">巡更点</el-menu-item> -->
        </el-submenu>
        <el-menu-item index="/download">技术支持</el-menu-item>
        <el-menu-item>
          <el-link href="https://zxx.payzxyj.com" style="font-size: 18px"
                   :underline="false">智小巡云平台</el-link>
        </el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
      </el-submenu>
    </el-menu>
    <!-- hidden-sm-only -->
    <!-- <div class="line"></div> -->
  </div>
</template>
<script>
// import routes from '@/router/index'
export default {
  props: {
    // page: {
    //   type: String,
    // },
  },
  data () {
    return {
      activeIndex: this.$route.path
      // barData:routes
      // page:""
      // activeIndex2: '1'
    };
  },
  created () {
    // console.log("看看routes",routes);
  },
  // 通过监听器解决  导航菜单在页面刷新时会回到默认项
  watch: {
    '$route' (to) {
      // 监听路由的变化  如果路由发生改变则当前tab栏默认值也相应改变
      this.activeIndex = to.path
    }
  },
  methods: {
    handleSelect (key) {
      // console.log(key,this.activeIndex,"看看路径");
      this.activeIndex = key
      // window.location.reload()
    }
  },
};
</script>
<style lang="less" scoped>
div {
  outline: none;
}
a {
  text-decoration: none;
}
.el-menu.el-menu--horizontal {
    border-bottom: solid 1px #e6e6e600 !important;
}
.el-menu-vertical-demo, .hidden-lg-and-up, .el-menu{
  .el-submenu{
.el-submenu__title{
  height:120px !important
}
  }
   .el-menu--horizontal {
    border-bottom: none;
    // font-weight: 600;
    // color:#666;
    // font-size: 20px;
    a {
      /* padding: 15px 30px; */
      font-size: 16px;
      color: #303133;
      text-decoration: none;
      // font-weight: bold;
    }
    .el-menu-item,
    .submenu {
      //   width: 50%;
      // border-bottom: none;
      //   height: 1rem;
      //   line-height: 0.5rem;
      //   text-align: center;
      a {
        /* padding: 15px 30px; */
        font-size:20px;
        color: #303133;
        text-decoration: none;
        font-weight: 500;
        // font-weight: bold;
      }
      font-size: 20px;
    }
    .el-submenu,
    .el-submenu__title {
      line-height: 30px;
    }
  }
}

.el-menu-item:focus,:hover {
        background-color: transparent;
      }
.hidden-md-and-down {
  .el-menu-item,
  .is-active {
    background: #ffffff00;
  }
  .el-submenu {
    .el-menu-item:focus,
    .el-menu-item:hover {
      background: #ffffff00;
    }
// .el-submenu__title:hover {
//     background-color: #ecf5ff00;
// }



  }
}

// .el-submenu__title {
//   border-bottom: none;
// }

// .el-menu--horizontal
</style>
<style lang="less">
.hidden-lg-and-up{
  .el-menu-item{
  font-size: 18px ;
  }
  .el-submenu{
.el-submenu__title{
  height:120px !important
  }
}
.small{
.el-submenu__title{
  height:56px !important;
  font-size: 18px ;
  }
}
}
</style>