<template>
  <div>
    <div style="text-align: center">
      <div class="head">
        <h1>广州智享云联科技有限公司</h1>
        <p class="hidden-sm-and-down">专业软/硬件一体化解决方案的科技企业</p>
        <el-image :src="url"
                  style="background-size: cover; height: 27.0833vw; width: 100%">
        </el-image>
      </div>
      <div>
        <div class="new-h1"
             style="margin-top:30px">
          <span class="title">企业概况</span>
          <el-divider>一家提供专业软/硬件一体化解决方案的科技企业</el-divider>
          <p style="
              text-align: left;
              padding: 0 10%;
              line-height: 30px;
              text-indent:35px;
            ">

            我司提供专业的智慧巡检应用解决方案，广泛应用政府企事业单位、园区、校园、工厂企业；提供传统保安巡更、电力巡检、水务管道巡检、公安巡逻、APP巡检等多种应用场景解决方案。</p>
          <p style="
              text-align: left;
              padding: 0 10%;
              line-height: 30px;
              text-indent:35px;
            ">
            我司自主研发的巡更产品系列：传统巡更棒、4G实时巡棒、GPS巡更棒、PAD巡更棒。并提供各种智慧巡检方案定制。
          </p>
        </div>
        <div class="new-h1"
             style="margin-top:30px">
          <span class="title">企业文化</span>
          <el-divider>专业、责任、高效、求实</el-divider>
          <div style="margin: 0 5%">
            <el-row :gutter="10" class="row1">
              <el-col  :xs="24" :sm="12" :md="12"
                :lg="6"
                :xl="6">
                <div style="position: relative">
                  <div style="
                      width: 100%;
                      background: rgb(0 0 0 / 0.5);
                      position: absolute;
                    "></div>
                  <img src="@/assets/bg8.jpg"
                       width="100%" />
                  <div style="
                      position: absolute;
                      top:0;
                      color: white;
                      width: 100%;
                      padding: 5px;
                    ">
                    <h3>专业</h3>
                    <p style="text-align: left">“用户至上、诚信经营”是智享云联一贯的宗旨。</p>
                  </div>
                </div>
              </el-col>
              <el-col  :xs="24" :sm="12" :md="12"
                :lg="6"
                :xl="6">
                <div style="position: relative">
                  <div style="
                      width: 100%;
                      background: rgb(0 0 0 / 0.5);
                      position: absolute;
                    "></div>
                  <img src="@/assets/bg9.jpg"
                       width="100%" />
                  <div style="
                      position: absolute;
                      top:0;
                      color: white;
                      width: 100%;
                      padding: 5px;
                    ">
                    <h3>责任</h3>
                    <p style="text-align: left">
                      严格而行之有效的售前、售中、售后服务，是公司服务
                      质量的保证。
                    </p>
                  </div>
                </div>
              </el-col>
              <el-col  :xs="24" :sm="12" :md="12"
                :lg="6"
                :xl="6">
                <div style="position: relative">
                  <div style="
                      width: 100%;

                      background: rgb(0 0 0 / 0.5);
                      position: absolute;
                    "></div>
                  <img src="@/assets/bg10.jpg"
                       width="100%" />
                  <div style="
                      position: absolute;
                      top:0;
                      color: white;
                      width: 100%;
                      padding: 5px;
                    ">
                    <h3>高效</h3>
                    <p style="text-align: left">
                      我们对每一位客户都详细了解、分析其需求，在充分
                      考虑产品兼容性、可扩展性及技术支持的前提下
                      提供全套综合解决方案。
                    </p>
                  </div>
                </div>
              </el-col>
              <el-col  :xs="24" :sm="12" :md="12"
                :lg="6"
                :xl="6">
                <div style="position: relative">
                  <div style="
                      width: 100%;

                      background: rgb(0 0 0 / 0.5);
                      position: absolute;
                    "></div>
                  <img src="@/assets/bg3.png"
                       width="100%" />
                  <div style="
                      position: absolute;
                      top:0;
                      color: white;
                      width: 100%;
                      padding: 5px;
                    ">
                    <h3>求实</h3>
                    <p style="text-align: left">
                      以达到建立长期战略合作伙伴关系、与合作伙伴共赢的目标。
                    </p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="new-h1">
        <div class="title">联系我们</div>

        <el-divider>广州智享云联科技有限公司</el-divider>
        <el-row :gutter="10"></el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <div>
            <ul style="padding: 20px">
              <li>
                <i class="el-icon-map-location"></i>
                <span>广州市黄埔区科学道112号绿地中央广场B2 809室</span>
              </li>
              <li>
                <i class="el-icon-phone-outline"></i>
                <span>业务热线：13242747939</span>
              </li>
              <li><i class="el-icon-chat-line-square"></i>
                <span>微信号：13242747939</span>
              </li>
              <li><i class="el-icon-service"></i>
                <span>QQ咨询：56187341</span>
              </li>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <map-container style="padding:20px"></map-container>
        </el-col>

      </div>
    </div>
  </div>
</template>
<script>
import MapContainer from "@/components/MapContainer.vue"
export default {
  name: "AboutView",
  components: { MapContainer },
  data () {
    return {
      url: require("@/assets/bg7.jpg"),
    };
  },
};
</script>
<style >
</style>
<style lang="less" scoped>
.row1{
  .el-col{
    padding: 5px;
  }
}
.head {
  position: relative;
  overflow: hidden;
  h1 {
    z-index: 2;
    position: absolute;
    top: 20%;
    width: 100%;
    color: white;
    font-size: 3rem;
    font-weight: 500;
  }
  p {
    z-index: 2;
    position: absolute;
    top: 45%;
    font-size: 25px;
    width: 100%;
    font-weight: 500;
    color: white;
  }
}
.el-row {
  .el-col {
    // width: 25%;
    // height: 25%;
    div {
      width: 100%;
      height: 250px;
      div {
        height: 250px;
      }
      img {
        height: 100%;
      }
    }
    // div{
    // width: 300px;
    // height: 300px;
    // }
  }
}
</style>
<style lang="less" scoped>
ul {
  // margin-left: 5%;
  // padding: 0;
  li {
  list-style: none;
  line-height: 2em;
  font-size: 2em;
  text-align: left;
  color: #ff6537;
  i {
    margin: 10px;
  }
}
}

</style>