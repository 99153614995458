<template>
  <div class=" hidden-sm-and-down">
        <el-menu :default-active="activeName2" mode="horizontal" @select="handleSelect" active-text-color="#ff6537" div style="display: flex; align-items: center" router>
          <el-menu-item index="/solution/one">云巡更解决方案</el-menu-item>
          <el-menu-item index="/solution/two">园区/校企安保巡更应用方案</el-menu-item>
          <el-menu-item index="/solution/three">智能电网GPS巡更应用方案</el-menu-item>
          <el-menu-item index="/solution/four">自来水厂智能巡更应用方案</el-menu-item>
        </el-menu>
  </div>
</template>
<script>
// import SoluTionYun from '@/views/SolutionPage/SoluTionOne.vue';
export default {
  components: {  },
    data(){
        return{
            activeName2:this.$route.path,
        }
    },
  // 通过监听器解决  导航菜单在页面刷新时会回到默认项
  watch: { 
    '$route' (to) {
      // 监听路由的变化  如果路由发生改变则当前tab栏默认值也相应改变
      this.activeName2 = to.path
    }
  },
  methods: {
    // handleSelect (key) {
    //   console.log(key);
    //   // console.log(this.$route.params.page);
    //   // this.page=this.$route.params.pa

    //   // if(this.$route.params.page===)
    // },
    handleSelect (key) {
      // console.log(key,this.activeName2,"看看路径");
      this.activeName2 = key
      // if()
      // window.location.reload()
    }
  },
}
</script>
