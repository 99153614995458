<template>
  <div>
    <div style="text-align: center">
      <div class="head">
        <h1>云巡更解决方案</h1>
        <el-image :src="url" style="background-size: cover; height: 27.0833vw; width: 100%">
        </el-image>
      </div>
<solu-tion></solu-tion>
      <div>
          <div class="new-h1">
            <div class="title">背景</div>
            <el-divider>当前对社区的物业巡检工作普遍采用早期的人员鉴到或领导抽查等较为传统的记录方式</el-divider>
            <div style="padding-top: 2em">
              <p>
                <!-- 当前对社区的物业巡检工作普遍采用早期的人员鉴到或领导抽查等较为传统的记录方式。<br /> -->
                随着时代的发展，它的弊端也越来越明显地表现出来。<br />
                这种巡逻方法可靠性差，效率低，真实性不足，容易作弊，管理者不容易准确掌握巡逻人员的工作状况。<br />
                现在绝大部分的社区都安装了电视监控系统。大部分人认为有了电视监控，再安装电子巡更属于重复投资。而实际情况不是这样的。电视监控往往和电子巡检是互补的。
              </p>
            </div>
          </div>
          <div class="new-h1">
            <div class="title">特色</div>
            <el-divider>智能管理软件是整个系统的“大脑”及“心脏”，是实现整个系统功能的指挥中心</el-divider>
            <div style="position: relative;padding-top: 2em">
              <div style="width: 540px;height: 280px;color: white;position: absolute;
                  right: 10%;
                  top: 10%;
                  background: #0000b45c;
                ">
                <p style="text-indent:35px;">智能管理软件是整个系统的“大脑”及“心脏”，是实现整个系统功能的指挥中心。</p>
                <p style="text-indent:35px;">智能管理软件的主要功能有：巡逻地点名称及相对应钮号的设置、数据读取、历史数据的浏览、巡逻计划的设置、巡逻计划的实施、局域网上远程数据传输等功能。</p>
              </div>
              <img src="@/assets/bg4.jpg"
                   style="width: 40%;height:40%" />
            </div>
          </div>
          <div  class="new-h1">
            <div  class="title">功能</div>
            <el-divider>云巡更管理能有效地监督和管理巡逻情况</el-divider>
            <div style="
                margin-top: 20px;
                display: flex;
                justify-content: space-around;    padding-top: 2em
              ">
              <div>
                准确地规划和记录巡逻人员的巡逻时间、次数及线路
                <h3>精准高效</h3>
              </div>
              <div>
                依托计算机信息网络，数据实时传输，资源共享
                <h3>快速便捷</h3>
              </div>
            </div>
            <el-progress :percentage="100"
                         status="success"
                         style="margin: 0 20%"></el-progress>
          </div>
          <div class="new-h1">
            <div class="title">如何工作</div>
            <el-divider />
            <img src="@/assets/s2.png" style="width: 30%;height:30%" />
          </div>
          <div class="new-h1">
            
            <div class="title">应用领域</div>
            <el-divider />
            <img src="@/assets/bg1.png"
                 style="width: 40%;height:40%" />
          </div>
      </div>
       <!-- <div>
        <el-collapse v-model="activeNames">
          <el-collapse-item title="背景" name="1">
            <div>
              <p>
                当前对社区的物业巡检工作普遍采用早期的人员鉴到或领导抽查等较为传统的记录方式。<br />
                随着时代的发展，它的弊端也越来越明显地表现出来。<br />
                这种巡逻方法可靠性差，效率低，真实性不足，容易作弊，管理者不容易准确掌握巡逻人员的工作状况。<br />
                现在绝大部分的社区都安装了电视监控系统。大部分人认为有了电视监控，再安装电子巡更属于重复投资。而实际情况不是这样的。电视监控往往和电子巡检是互补的。
              </p>
            </div>

          </el-collapse-item>
          <el-collapse-item title="特色"
                            name="2">
            <div style="position: relative">
              <span style="width: 540px;height: 280px;color: white;position: absolute;
                  right: 10%;
                  top: 10%;
                  background: #0000b45c;
                ">
                智能管理软件是整个系统的“大脑”及“心脏”，是实现整个系统功能的指挥中心。
                <br />
                智能管理软件的主要功能有：巡逻地点名称及相对应钮号的设置、数据读取、历史数据的浏览、巡逻计划的设置、巡逻计划的实施、局域网上远程数据传输等功能。
              </span>
              <img src="@/assets/bg4.jpg"
                   style="width: 40%" />
            </div>
          </el-collapse-item>
          <el-collapse-item title="功能"
                            name="3">
            <div>云巡更管理能有效地监督和管理巡逻情况</div>
            <div style="
                margin-top: 20px;
                display: flex;
                justify-content: space-around;
              ">
              <div>
                准确地规划和记录巡逻人员的巡逻时间、次数及线路
                <h3>精准高效</h3>
              </div>
              <div>
                依托计算机信息网络，数据实时传输，资源共享
                <h3>快速便捷</h3>
              </div>
            </div>
            <el-progress :percentage="100"
                         status="success"
                         style="margin: 0 20%"></el-progress>
          </el-collapse-item>
          <el-collapse-item title="如何工作" name="4">
            <img src="@/assets/s2.png" style="width: 30%" />
          </el-collapse-item>
          <el-collapse-item title="应用领域"
                            name="5">
            <img src="@/assets/bg1.png"
                 style="width: 40%" />
          </el-collapse-item>
        </el-collapse>
      </div> -->
    </div>
  </div>
</template>
<script>
import SoluTion from '@/components/SoluTion.vue';
export default {
  components: { SoluTion },
  name: "SoluTionOne",
  data () {
    return {
      activeIndex: "/solution/one",
      url: require("@/assets/banner4.jpg"),
      activeNames: ["1", "2", "3", "4", "5"],
    };
  },
};
</script>
<style lang="less">
.head {
  h1 {
    z-index: 2;
    position: absolute;
    top: 1em;
    bottom: 3em;
    width: 100%;
    color: white;
    font-size: 5em;
    font-weight: 500;
  }
}
.el-collapse-item {
  div {
    color: #666;
    line-height: 24px;
    font-size: 16px;
    p {
      padding: 0 10%;
      line-height: 30px;
    }
  }
}
.span_h1,
.el-collapse-item__header {
  border-right: 3px solid #ff6537;
  /* margin: 0px 30%; */
  padding: 0 10px 0 0;
  font-size: 25px;
  font-weight: bold;
}
</style>