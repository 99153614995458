<template>
  <div>
    <div style="text-align: center">
      <div class="head">
        <!-- <h1>校企安保巡更应用方案</h1> -->
        <el-image :src="url" style="background-size: cover; height: 27.0833vw; width: 100%"> </el-image>
      </div>
<solu-tion></solu-tion>
        <div>
          <div class="new-h1">
            <div class="title">简介</div>
             <el-divider>园区/校企安保巡更应用方案</el-divider>
             <p>
                巡检人员可在云平台自由设置固定的巡更线路，并可指派巡更时间、巡更人员等，帮助物管合理的调度人员，确保所有的巡更点无遗漏。员工的巡更结果，还可作为对员工的能力考核。云平台自动生成巡更记录，便于物业人员调度。云平台实时接收巡检手机上传的巡检数据，自动生成巡更轨迹，可远程实时查实时掌握每个人员各基站的巡检状况，便于物业人员调度，在减少了物管人员工作量的同时，也大大节省了管理成本。
             </p>
            <el-progress :percentage="100" status="success"  style="margin:0 20%"></el-progress>
          </div>
          <div class="new-h1">
            <div class="title">框架图</div>
             <el-divider />
            <img src="@/assets/p14.png" style="width:80%;height:80%"> 
          </div>
            <div class="new-h1">
            <div class="title">方案特点</div>
             <el-divider />
          <!-- <div title="" name="3" style="text-align:left;">
            <div style="padding-left:30px"> -->
          <div style="text-align:left;padding:20px">
（1）巡更系统包括：巡更棒、巡更点、人员扣、管理软件等主要部分。<br>
（2）巡更棒：巡检人员随身携带，用于巡检。<br>
（3）巡更点：布置于巡检线路中，无需电源、无需布线。<br>
（4）管理软件：用于查询、统计供管理人员使用。<br>
（5）系统统整体性能价格比高，应用简洁，技术精湛，价位较低。
              </div>
            </div>
          </div>
          <div class="new-h1">
            <div class="title">系统特点</div>
             <el-divider />
          <div style="text-align:left;padding:20px">
（1）操作简单，安装方便。<br>
（2）安全可靠；巡更点与巡更棒有防水、防磁、耐高温和低温。巡更记录安全可靠，巡更棒可储存10万条记巡更和报警记录。<br>
（3）数据上传实时性；数据通过4G物联网对巡点原始记录进行实时上传。<br>
（4）巡更线路与巡更计划设置灵活多样，适用不同的排班与单位巡更安全要求。<br>
（5）一键报警，报警信息实时推送。<br>
（6）可以分线路、分计划自制巡更电子地图。<br>
（7）多种样式巡更统计报表与考核分析，满足不同级别人员和领导业务需求。<br>
（8）兼容性好，可根据企业需要，各种设备与系统自由组合。
          </div>
          </div>
                    <div class="new-h1">
            <div class="title">应用效果</div>
             <el-divider />
            <img src="@/assets/p13.png" style="width:80%;height:80%">
          </div>
                   <div class="new-h1">
            <div class="title">应用场景</div>        
          <div>
            <el-row :gutter="10">
              <el-col :span="8">
                <el-card>
                  <img src="@/assets/p15.png" />
                  <div>
                    <span>企业园区</span>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card>
                  <img src="@/assets/p16.png" />
                  <div>
                    <span>校园区</span>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card>
                  <img src="@/assets/p17.png" />
                  <div>
                    <span>居民区</span>
                  </div>
                </el-card>
              </el-col>
            </el-row>              
            </div>
          </div>
        </div>
      <!-- <div>
        <div v-model="activeNames">
          <div title="简介" name="1" >
            <div>
             <h3>园区/校企安保巡更应用方案</h3>
             <p>
                巡检人员可在云平台自由设置固定的巡更线路，并可指派巡更时间、巡更人员等，帮助物管合理的调度人员，确保所有的巡更点无遗漏。员工的巡更结果，还可作为对员工的能力考核。云平台自动生成巡更记录，便于物业人员调度。云平台实时接收巡检手机上传的巡检数据，自动生成巡更轨迹，可远程实时查实时掌握每个人员各基站的巡检状况，便于物业人员调度，在减少了物管人员工作量的同时，也大大节省了管理成本。
             </p>
            <el-progress :percentage="100" status="success"  style="margin:0 20%"></el-progress>
            </div>
          </div>
          <div title="框架图" name="2">
            <img src="@/assets/p14.png" style="width:80%"> 
          </div>
          <div title="方案特点" name="3" style="text-align:left;">
            <div style="padding-left:30px">
              <p>
（1）巡更系统包括：巡更棒、巡更点、人员扣、管理软件等主要部分。<br>
（2）巡更棒：巡检人员随身携带，用于巡检。<br>
（3）巡更点：布置于巡检线路中，无需电源、无需布线。<br>
（4）管理软件：用于查询、统计供管理人员使用。<br>
（5）系统统整体性能价格比高，应用简洁，技术精湛，价位较低。
              </p>
            </div>
          </div>
          <div title="系统特点" name="4" style="text-align:left;">
            <div style="padding-left:30px">
              <p>
（1）操作简单，安装方便。<br>
（2）安全可靠；巡更点与巡更棒有防水、防磁、耐高温和低温。巡更记录安全可靠，巡更棒可储存10万条记巡更和报警记录。<br>
（3）数据上传实时性；数据通过4G物联网对巡点原始记录进行实时上传。<br>
（4）巡更线路与巡更计划设置灵活多样，适用不同的排班与单位巡更安全要求。<br>
（5）一键报警，报警信息实时推送。<br>
（6）可以分线路、分计划自制巡更电子地图。<br>
（7）多种样式巡更统计报表与考核分析，满足不同级别人员和领导业务需求。<br>
（8）兼容性好，可根据企业需要，各种设备与系统自由组合。
              </p>
            </div>
          </div>
            <div title="应用效果" name="5">
            <img src="@/assets/p13.png" style="width:80%">
          </div>
          <div title="应用场景" name="6">
          <div>
            <el-row :gutter="10">
              <el-col :span="8">
                <el-card>
                  <img src="@/assets/p15.png" />
                  <div>
                    <span>企业园区</span>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card>
                  <img src="@/assets/p16.png" />
                  <div>
                    <span>校园区</span>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card>
                  <img src="@/assets/p17.png" />
                  <div>
                    <span>居民区</span>
                  </div>
                </el-card>
              </el-col>
            </el-row>              
            </div>
          </div>
        </div>
      </div> -->
    </div>
  <!-- </div> -->
</template>
<script>
import SoluTion from '@/components/SoluTion.vue';
export default {
  name: "SoluTionTwo",
  components:{SoluTion},
  data() {
    return {
      url: require("@/assets/arrow2.png"),
      activeNames: ["1",'2','3','4','5','6'],
    };
  },
};
</script>
<style lang="less" scoped>
// .head {
//   margin-bottom: 10px;
// }
.el-card{
  img{
    height: 200px;
  }
}
</style>